<template>
  <NConfigProvider :locale="zhCN" :date-locale="dateZhCN">
    <AppProvider>
      <div class="app-container z-40 transition flex relative">
        <div class="flex flex-col " style="width: 100%">
          <router-view/>
        </div>
        <div class="toolbar-fixed" @click="customer">
          <img src="./assets/images/customer.png" alt="综评问答" title="综评问答"/>
        </div>
        <div class="toolbar-fixed toolbar-fixed-home" @click="backHome">
          <img src="./assets/images/home.png" alt="返回首页" title="返回首页"/>
        </div>
      </div>
    </AppProvider>
  </NConfigProvider>
</template>
<script setup>
import AppProvider from '@/components/AppProvider'
import {zhCN, dateZhCN} from 'naive-ui'
import {onMounted} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import Cookies from "js-cookie";

const route = useRoute()
const router = useRouter()

function customer() {
  console.log('customer');
  if (isMobile()) {
    window.open("http://120.46.51.128/webchatbot-new/#/h5chat?sysNum=1706004011906&sourceId=8362&lang=zh_CN");
  } else {
    window.open("http://120.46.51.128/webchatbot-pc/#/chatNew?sysNum=1706004011906&sourceId=8361&lang=zh_CN");
  }
}

function backHome() {
  router.push({path: '/'})
}


onMounted(async () => {
  await router.isReady();
  let tenantId = route.query.tenantId;
  let inviteId = route.query.inviteId;
  console.log('app onBeforeMount', tenantId, inviteId);
  if (tenantId) {
    Cookies.set("TENANT_ID", tenantId)
  }
  if (inviteId) {
    Cookies.set("INVITE_ID", inviteId)
  }
})

function isMobile() {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag;
}

</script>
<style lang="scss" scoped>
.app-container {
  display: flex;

  .router-view {
    background-color: #fff;
  }
}

.toolbar-fixed {
  position: fixed;
  right: 1rem;
  bottom: 5rem;
  font-size: 0.5rem;
  border: 1px solid #d14538;
  text-align: center;
  background-color: #d14538;
  color: #fff;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;

  img {
    width: 1rem;
    height: 1rem;
  }
}

.toolbar-fixed-home {
  bottom: 2rem;
}

.footer-chat {
  li {
    flex: 1;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
    cursor: pointer;

    &.active {
      color: #4b9e5f;

    }
  }

}
</style>

<style lang="scss">
.app-container {
  display: flex;

  .router-view {
    background-color: #fff;
  }
}

.login {
  .n-card-header__main {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 28px;
    color: #333333;
    text-align: center;
  }

  .n-card__content {
    padding: 0 15px !important;
    padding-bottom: 40px !important;
  }
}

</style>
